import React, { Suspense } from "react";
import {
  Grid,
  Avatar,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { SystemState } from "../../store/store";
import CallIcon from "@material-ui/icons/Call";
import { Stream, AccountData } from "../../declarations/declarations";
import { getUserId,} from "../../helpers/helpers";
import Logo from "../../assets/images/logo.png";
import MeetingItemHorizonalCollection from "../../components/MeetingItemHorizonalCollection/MeetingItemHorizonalCollection";
import { ThunkDispatch } from "redux-thunk";
import { AppStateActionTypes } from "../../store/actionTypes";
import { bindActionCreators } from "redux";
import { MeetingItemsReduxer } from "../../store/reduxer";
import AddStreamsDialog from "../../layout/components/Dialogs/AddStreamsDialog/AddStreamsDialog";
import { getAllUserNames } from "../../stitch/app";
import "./index.scss";
import { updateStartMeetingState } from "../../store/actionCreators";

const DrawerButton = React.lazy(() => import("./components/DrawerButton"));
const StreamDrawer = React.lazy(() => import("./components/Drawer"));
const TeamInfo = React.lazy(() => import("./components/TeamInfo"));

interface StateProps {
  streams: Stream[];
  drawer: boolean;
  AccountData: AccountData;
}

interface FuncProps
  extends RouteComponentProps<{
    stream_id: string;
  }> {}

interface DispatchProps {
  updateStartDialogState: (payload: {state: boolean, stream_id: string}) => void;
  getMeetingItems: (query: any) => void;
}

type Props = StateProps & FuncProps & DispatchProps;

const StreamPage: React.FC<Props> = ({
  streams,
  AccountData,
  match: {
    params: { stream_id },
  },
  updateStartDialogState,
  getMeetingItems,
}) => {
  const stream = streams.filter(
    (stream) => stream._id.toHexString() === stream_id
  )[0];
  const [dialogState, setDialogState] = React.useState<boolean>(false);

  const [allUsers, setAllUsers] = React.useState<Array<[string, any]>>([]);

  React.useEffect(() => {
    (async () => {
      const users = await getAllUserNames();
      setAllUsers(users);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      await getMeetingItems({
        who: getUserId() as string,
        complete: false,
      });
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream_id]);

  const [drawerState, toggleState] = React.useState<boolean>(false);
  const handleDrawer = () => {
    toggleState(!drawerState);
  };

  const [allAdmins, setAllAdmins] = React.useState<
    Array<{ firstName: string; lastName: string }>
  >([]);

  React.useEffect(() => {
    (() => {
      let adminArray = [];
      for (var i = 0; i < stream.admins.length; i++) {
        let current_admin_id = stream.admins[i];
        const index = allUsers.findIndex(
          (user) => user[0] === current_admin_id
        );
        let adminName = allUsers[index] && allUsers[index][1];
        let FirstName = adminName && adminName.split(" ")[0];
        let LastName = adminName && adminName.split(" ")[1];
        let admin = { firstName: FirstName, lastName: LastName };
        adminArray.push(admin);
      }
      setAllAdmins(adminArray);
    })();
  }, [allUsers, stream]);

  return (
    <Grid className={(drawerState ? "header-shift " : "header-normal") + " stream-main-container"}>
      <Suspense fallback={<CircularProgress />}>
        <Grid className="stream-header-container">
          <Suspense fallback={<CircularProgress />}>
            <Grid container>
              <Grid item xs={3}>
                <TeamInfo
                  logoUrl={Logo}
                  CompanyName={AccountData.accountName}
                  StreamName={stream.name}
                />
              </Grid>
              <Grid item xs={9} className="text-align-right">
                <Grid className="avatar-container inline vertical-middle">
                  {allAdmins &&
                    allAdmins.map((item) => {
                      return (
                        <Grid className="inline avatar-container-single vertical-top">
                          {item.firstName && item.lastName && (
                            <Avatar
                              title={`${item.firstName}${item.lastName}`}
                              children={`${item.firstName[0]}${item.lastName[0]}`}
                              className="avatar-circle vertical-top"
                            />
                          )}
                        </Grid>
                      );
                    })}
                </Grid>
                <Grid className="inline">
                  <Button
                    variant="contained"
                    color="primary"
                    className="start-button-main primary-blue-background"
                    onClick={() => updateStartDialogState({state: true, stream_id})}
                  >
                    <CallIcon className="call-icon" />
                    <Typography className="button-text">
                      Start a Meeting
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Suspense>
        </Grid>
        {/* <StreamHeader stream={stream} account={AccountData}></StreamHeader> */}
      </Suspense>
      <Grid item md={12} sm={12}>
        {allUsers && dialogState && (
          <Grid style={{ textAlign: "center" }}>
            <AddStreamsDialog
              allUsers={allUsers}
              stream={stream}
              setState={setDialogState}
              state={dialogState}
              toggleDrawer={() => {}}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{
            width: "98%",
            marginTop: "10px",
            padding: "5px",
            minHeight: "75vh",
            maxHeight: "100%",
          }}
        >
          <MeetingItemHorizonalCollection
            idPrefix={`${stream.name}-collection`}
            lists={stream.lists}
            name={"Meeting Items"}
          ></MeetingItemHorizonalCollection>
        </Grid>
      </Grid>
      <Suspense fallback={<CircularProgress />}>
        <DrawerButton state={drawerState} changeState={handleDrawer} />
        <StreamDrawer state={drawerState} stream_id={stream_id} />
      </Suspense>
    </Grid>
  );
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    streams: state.data.streams,
    drawer: state.app.myItemsDrawer,
    AccountData: state.data.accountData[0],
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppStateActionTypes>
): DispatchProps => {
  return {
    updateStartDialogState: bindActionCreators(updateStartMeetingState, dispatch),
    getMeetingItems: bindActionCreators(
      MeetingItemsReduxer.getFiltered,
      dispatch
    ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StreamPage)
);
